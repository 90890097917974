










































import { defineComponent } from '@vue/composition-api'
import useFooter from '@/hooks/layout/useFooter'

export default defineComponent({
  name: 'DeskFooter',
  setup() {
    const { about, handleRedirect, pcBg, copyright, footerName } = useFooter()
    return {
      about,
      pcBg,
      copyright,
      footerName,
      handleRedirect
    }
  }
})
