import { render, staticRenderFns } from "./desk.vue?vue&type=template&id=3c29aeed&scoped=true&"
import script from "./desk.vue?vue&type=script&lang=ts&"
export * from "./desk.vue?vue&type=script&lang=ts&"
import style0 from "./desk.vue?vue&type=style&index=0&id=3c29aeed&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c29aeed",
  null
  
)

export default component.exports